import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main } from './page/Main';
import { Detail } from './page/Detail';
import { WriteForm } from './page/WriteForm';
import Error404 from './error/Error404';
import { Header } from './common/Header';
import { Footer } from './common/Footer';
import { WriteCategory } from './manage/WriteCategory';
import { Test } from './test/Test';
import { Ad } from './common/Ad';

const App = () => {
  return (
    <BrowserRouter>
      <div className="w-full flex flex-row justify-center items-start">
        <div id="left-ad-aria" className="lg:block lg:w-[200px] lg:flex-shrink-0 h-screen">
          <Ad />
        </div>
        <div className="flex-grow max-w-screen-lg min-h-screen flex flex-col">
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Main />}></Route>
              <Route path="/company" element={<Detail />}></Route>
              <Route path="/category" element={<WriteCategory />}></Route>
              <Route path="/write" element={<WriteForm />}></Route>
              <Route path="/test" element={<Test />}></Route>
              <Route path="*" element={<Error404 />}></Route>
            </Routes>
          </main>
          <Footer />
        </div>
        <div id="right-ad-aria" className="lg:block lg:w-[200px] lg:flex-shrink-0 h-screen">
          <Ad />
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
